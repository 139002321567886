/*
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version:
 * @Author: Duanshuai
 * @Date: 2022-01-21 14:31:28
 * @LastEditors: ------
 * @LastEditTime: 2022-02-28 10:05:06
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "amfe-flexible/index.js";
import {
    Button,
    Image as VanImage,
    Tab,
    Tabs,
    Field,
    Col,
    Area,
    Row,
    Form,
    Tabbar,
    TabbarItem,
    NavBar,
    Search,
    Lazyload,
    Pagination,
    Icon,
    Popup,
    Grid,
    GridItem,
    Picker,
    Radio,
    Cell,
    cellGroup,
    Empty,
    NoticeBar,
    RadioGroup,
    Calendar,
    Popover,
    Overlay,
    Checkbox,
    DatetimePicker,
    CheckboxGroup,
    DropdownMenu,
    DropdownItem,
    Dialog ,
    calendar,
} from "vant";
// import "vant/lib/index.css";

Vue.use(Button)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(calendar)
    .use(VanImage)
    .use(Dialog)
    .use(Tab)
    .use(Tabs)
    .use(Field)
    .use(Radio)
    .use(DatetimePicker)
    .use(Overlay)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(RadioGroup)
    .use(Calendar)
    .use(Popover)
    .use(Col)
    .use(Area)
    .use(Row)
    .use(TabbarItem)
    .use(Grid)
    .use(GridItem)
    .use(Tabbar)
    .use(Empty)
    .use(NavBar)
    .use(Search)
    .use(Pagination)
    .use(NoticeBar)
    .use(Popup)
    .use(Cell)
    .use(cellGroup)
    .use(Picker)
    .use(Lazyload, {
        lazyComponent: true,
    })
    .use(Form)
    .use(Icon);

Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
    if(localStorage.getItem("token")){
        store.commit("setToken",localStorage.getItem("token"));
    }
    if(to.meta.requireAuth){
        if(store.state.token){
          next()
        }else{
          next({path:'/login'})
        }
    }else{
    next()
    }
})
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");