/*
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version:
 * @Author: Duanshuai
 * @Date: 2022-01-24 08:53:32
 * @LastEditors: ------
 * @LastEditTime: 2022-01-26 09:59:45
 */
import Vue from "vue";
import Vuex from "vuex";
import { Notify } from "vant";
import { login, getCode } from "@/api";
import router from "@/router";
import { getItem, setItem } from "@/utils/storage";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: localStorage.getItem("token") || "",
        info: JSON.parse(localStorage.getItem("info")) || "",
        contract: getItem("contract"),
    },
    mutations: {
        setToken(state, value) {
            state.token = value;
            localStorage.setItem("token", value);
        },
        setInfo(state, value) {
            state.info = value;
            localStorage.setItem("info", value);
        },
        setContract(state, contract) {
            state.contract = contract;
            setItem("contract", state.contract);
          },
    },
    actions: {
        // 登录请求
        actLogin(context, userInfo) {
            const { username, password } = userInfo;
            return getCode().then((res) => {
                if (res.data.success == true) {
                    return new Promise((resolve, reject) => {
                        login({
                                username,
                                password,
                                captcha: res.data.result.code,
                                checkKey: res.data.result.key,
                            })
                            .then((data) => {
                               
                                if(data.data.code == 200){
                                    Notify({
                                        type: "success",
                                        message: data.data.message,
                                        duration: 1000,
                                    });
                                }
                                if(data.data.code == 500){
                                  
                                    Notify({
                                        type: "danger",
                                        message: data.data.message,
                                        duration: 1000,
                                    });
                                }else{
                                    Notify({
                                        type: "primary",
                                        message: data.data.message,
                                        duration: 1000,
                                    });
                                }
                                
                                this.commit("setToken", data.data.result.token);
                                this.commit(
                                    "setInfo",
                                    JSON.stringify(data.data.result.userInfo)
                                );
                                router.push({ path: "/" });
                                resolve();
                            })
                            //.catch((err) => {
                                // Notify({
                                //     type: "warning",
                                //     message: "登录失败",
                                //     duration: 2000,
                                // });
                                // reject(err);
                            //});
                    });
                }
            });
        },
    },
    modules: {},
});