/*
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version:
 * @Author: Duanshuai
 * @Date: 2022-01-21 14:31:28
 * @LastEditors: ------
 * @LastEditTime: 2022-02-28 13:39:10
 */
import Vue from "vue";
import VueRouter from "vue-router";
//import store from "@/store/";

Vue.use(VueRouter);

const routes = [{
        path: "/login",
        name: "Login",
        meta: { requireAuth: false },
        component: () =>
            import ("../views/login"),
    },
    {
        path: "/",
        meta: { requireAuth: true },

        component: () =>
            import ("../views/layout"),
        children: [{
                path: "/",
                name: "Home",
                meta: { requireAuth: true },
                component: () =>
                    import ("../views/home/index.vue"),
            },
            {
                path: "customer",
                name: "Customer",
                meta: { requireAuth: true },
                component: () =>
                    import ("../views/customer/index.vue"),
            },
            {
                path: "/manage",
                name: "Manage",
                meta: { requireAuth: true },
                component: () =>
                    import ("../views/manage/index.vue"),
            },
        ],
    },
    {
        path: "/fangTan",
        name: "FangTan",
        meta: { requireAuth: true },
        component: () =>
            import ("../views/customer/fangTan.vue"),
    },
    {
        path: "/lookcontainer",
        name: "Lookcontainer",
        meta: { requireAuth: true },
        component: () =>
            import ("../views/customer/lookContainer.vue"),
    },
    {
        path: "/customerInfo",
        name: "CustomerInfo",
        meta: { requireAuth: true },
        component: () =>
            import ("../views/pop/customer.vue"),
    },
    {
        path: "/family",
        name: "Family",
        meta: { requireAuth: true },
        component: () =>
            import ("../views/pop/family.vue"),
    },
    {
        path: "/create",
        name: "Create",
        meta: { requireAuth: true },
        component: () =>
            import ("../views/create/index.vue"),
    },
    {
        path: "/bulu",
        name: "Bulu",
        meta: { requireAuth: true },
        component: () =>
            import ("../views/customer/buLu.vue"),
    },
    {
        path: "/serveDetail",
        name: "ServeDetail",
        meta: { requireAuth: true },
        component: () =>
            import ("../views/manage/serverDetail.vue"),
    },
    {
        path: "/chooseServe",
        name: "ChooseServe",
        meta: { requireAuth: true },
        component: () =>
            import ("../views/manage/chooseServe.vue"),
    },
    {
        path: "/payment",
        name: "Payment",
        meta: { requireAuth: true },
        component: () =>
            import ("../views/manage/payment.vue"),
    },
    {
        path: "/easy",
        name: "easy",
        meta: { requireAuth: true },
        component: () =>
            import ("../views/easy"),
    },
    {
        path: "/file",
        name: "File",
        meta: { requireAuth: true },
        component: () =>
            import ("../views/file/index"),
    },
    {
        path: "/detail",
        name: "Detail",
        meta: {
            requireAuth: true,
        },
        component: () =>
            import ("../views/file/detail"),
    },
    {
        path: "/register",
        name: "register",
        meta: { requireAuth: false },
        component: () =>
            import ("../views/register/index"),
    },
    {
        path: "*",
        name: "404",
        component: () =>
            import ("../views/404.vue"),
    },
];

const router = new VueRouter({
    routes,
});

//白名单

export default router;